<template>
  <div class="building-container">
    <v-row class="pb-10 justify-center">
      <v-col class="d-flex child-flex pa-1" cols="10" sm="8" md="6" lg="6" xl="5">
        <v-img :src="building.teaserImage" aspect-ratio="1.7778"/>
      </v-col>
    </v-row>
    <h1 id="building">DAS GEBÄUDE</h1>
    <div class="title-separator-container">
      <div class="title-separator"/>
    </div>
    <div class="building-description-container">
      <div class="building-description">
        In die sanft abfallende Hanglage werden drei ineinander verwobene Baukörper behutsam eingefügt. <br/><br/>

        Auf verschieden Ebenen sind 5 Wohneinheiten mit großzügigen, nach Südwesten orientierten
        Fassadenöffnungen untergebracht. <br/><br/>

        Durch die geschickte Verdrehung der Baukörper eröffnen sich verschiedenste Blickwinkel und Perspektiven, sowie
        eine helle Hofsituation im erdanliegendem Zugangsbereich. <br/><br/>

        Die Zufahrt sowie der Zugang erfolgen von der Nordseite. Im Norden liegt oberhalb des Gebäudes ein kleiner
        Hügel mit einem unverbaubaren, großen Garten voller Apfelbäume. <br/><br/>

        An der Nordseite des Gebäudes befinden sich auch die PKW -Stellplätze, eine E-Ladestation (optional), sowie
        ein überdachter Fahrradabstellplatz. Über eine Brücke gelangt man zu den einzelnen Wohnungen. <br/><br/>

        Die beiden Gartenwohnungen bilden die Sockelzone für drei Maisonetten. Alle Einheiten erhalten großzügige
        Kellerabteile. <br/><br/>

        Die Konfiguration des Gesamtgebäudes erzielt für alle Wohnungen viel natürliches Licht, individuelle
        Rückzugsmöglichkeiten sowie Ausblicke in die umliegende Landschaft. <br/><br/>

        Das Gebäude wird in Holzmassivbauweise errichtet und erhält eine vorgehängte Holzfassade. Die großen
        Fensterelemente in Holz-Alu Rahmenkonstruktion mit 3 Scheibenverglasung unterstreichen den Blick in die
        Umgebung. Hauptaugenmerk wird auf die Verwendung von heimischen und nachhaltigen Baustoffen gelegt. <br/><br/>

        Die hochwirksamen Holzdämmstoffe tragen zu einer zukunftsweisenden und kostengünstigen Energiebilanz
        bei. <br/><br/>

        Hochwertiger Holzparkett in den Wohn- und Aufenthaltsräumen, sowie keramischer Belag in den Nass- und
        Nebenräumen runden gemeinsam mit den hellen Innenwänden das Ensemble ab. <br/><br/>

        Die warme Aura des Holzes wird verstärkt durch die von Raum zu Raum individuell einstellbare
        Fußbodenheizung. <br/><br/>

        Die Bäder bieten mit bodenebenen Duschen, Handtuchtrockner sowie hochwertigen Armaturen höchsten Komfort.

        <v-row class="pt-10 justify-center">
          <v-col v-for="(image, index) in building.images" :key="'gallery_building_image_' + index"
                 class="d-flex child-flex pa-1" cols="4" sm="4" md="3" lg="3" xl="2">
            <v-img :src="image" aspect-ratio="1.7778" @click="building.index = index"/>
          </v-col>
          <v-col cols="12" class="mt-10">
            <vimeo-player id="581148525" thumbnail="/images/building/building_image_1.jpg" :large="true"/>
          </v-col>
        </v-row>
      </div>
    </div>
    <vue-gallery :images="building.images" :index="building.index" id="building_gallery"/>
  </div>
</template>

<script>
import { mdiDownload } from "@mdi/js";
import VueGallery from 'vue-gallery';

const VimeoPlayer = () => import(/* webpackChunkName: "VimeoPlayer" */ '../VimeoPlayer');

export default {
  name: "Building",
  components: {
    VueGallery, VimeoPlayer
  },
  data: () => ({
    icons: {
      download: mdiDownload
    },
    building: {
      index: null,
      teaserImage: '/images/building/building_image_1.jpg',
      images: [
        '/images/building/building_image_6.jpg',
        '/images/building/building_image_5.jpg',
        '/images/building/building_image_2.jpg',
        '/images/building/building_image_10.jpg',
      ]
    }
  })
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/layout.scss";
.building-container {
  padding-top: 60px;
  margin-left: 185px;
  margin-right: 185px;
  text-align: center;
  @include MQ(L) {
    margin-left: 135px;
    margin-right: 135px;
  }
  @include MQ(M) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @include MQ(S) {
    margin-left: 25px;
    margin-right: 25px;
  }
  @include MQ(XS) {
    padding-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
  h1 {
    font-size: 3em;
    @include MQ(XS) {
      font-size: 2em;
    }
  }
  .title-separator-container {
    display: flex;
    justify-content: center;
    .title-separator {
      width: 150px;
      height: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .building-description-container {
    padding-top: 40px;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    @include MQ(XS) {
      font-size: 1em;
    }
    .building-description {
      max-width: 1200px;
      .v-image {
        cursor: pointer;
        a {
          position: absolute;
          padding: 0 5px;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
